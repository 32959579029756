import { FC, ReactNode } from "react";

import { FormAlert, Portal } from "@components";
import { Container } from "@ui";

import { Header } from "./Header";

type LayoutProps = {
  children: ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => (
  <>
    <Header />
    <Portal>
      <div>
        <FormAlert />
      </div>
    </Portal>
    <Container paddingTop={ 20 } paddingBottom={ 80 }>
      { children }
    </Container>

  </>
);
