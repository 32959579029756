import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { Breadcrumbs, QuestionAccordionList } from "@components";
import {
  useDeleteLevelMutation,
  useGetLevelPermissionsQuery,
  useGetLevelQuery,
} from "@store/levelApi";
import { useGetQuestionListQuery } from "@store/questionApi";
import { useGetSkillQuery } from "@store/skillApi";
import { Params } from "@types";
import {
  BreadcrumbsSkeleton,
  Button,
  EntityItemSkeleton,
  PageDescriptionWrapper,
  PageHeaderWrapper, PageInfoWrapper,
  PageTitle,
  PageTitleActions, PageTitleWrapper,
  QuestionListSkeleton,
} from "@ui";

export const LevelDetailPage = () => {
  const { skillSlug, levelSlug } = useParams<Params>();
  const { data: skillItem, isLoading: isSkillLoading } = useGetSkillQuery(skillSlug as string);
  const { data: levelItem, isLoading: isLevelLoading, isError } = useGetLevelQuery({
    levelSlug: levelSlug as string,
    skillSlug: skillSlug as string,
  });

  const { data: permissions } = useGetLevelPermissionsQuery({
    levelId: levelItem?.id as string,
    skillId: levelItem?.skill_id as string,
  });
  const [deleteLevel] = useDeleteLevelMutation();
  const { data: levelQuestions, isLoading: isQuestionLoading } = useGetQuestionListQuery({
    levelSlug: levelSlug as string,
    skillSlug: skillSlug as string,
  });
  const navigate = useNavigate();

  const deleteLevelHandler = (skillId: string, levelId: string) => {
    const approve = confirm("Вы хотите удалить навык?");
    if (approve) {
      deleteLevel({ skillId, levelId })
        .then((res) => {
          if ("data" in res) {
            navigate(`/skills/${skillSlug}`);
          }
        });
    }
  };

  if (isError) {
    navigate(`/skills/${skillSlug}`);
  }

  return (
    <>
      <PageHeaderWrapper>
        { !isSkillLoading && !isLevelLoading
          ? <Breadcrumbs skillName={ skillItem?.title } levelName={ levelItem?.title } />
          : <BreadcrumbsSkeleton countElements={ 3 } /> }
        { !isLevelLoading && levelItem ? (
          <>
            <PageTitleWrapper>
              <PageInfoWrapper>
                <PageTitle>
                  { levelItem.title }
                </PageTitle>
              </PageInfoWrapper>
              <PageTitleActions>
                { permissions && permissions.canRemove
                  && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={ () => deleteLevelHandler(levelItem.skill_id, levelItem.id) }
                    >
                      Удалить
                    </Button>
                  ) }

                { permissions && permissions.canEdit
                  && (
                    <Link to={ `/skills/${skillSlug}/edit/${levelSlug}` }>
                      <Button variant="contained">Редактировать</Button>
                    </Link>
                  ) }

              </PageTitleActions>
            </PageTitleWrapper>
            <PageDescriptionWrapper>
              <ReactMarkdown>
                { levelItem.description }
              </ReactMarkdown>
            </PageDescriptionWrapper>
          </>

        ) : <EntityItemSkeleton /> }
      </PageHeaderWrapper>
      <div>
        { !isQuestionLoading ? <QuestionAccordionList list={ levelQuestions } /> : <QuestionListSkeleton /> }
      </div>
    </>
  );
};
