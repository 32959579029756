export const levelFormErrors = {
  title: "Заполните название",
  slug: "Заполните символьный код",
  slugNotValid: "Только буквы латиницы, цифры и '-' ",
  approvals: "Заполните количество подтверждений",
  approvalsNotValid: "Только числовое значение",
};

export const levelSuccessAlertMessage = {
  create: "Уровень создан",
  edit: "Уровень изменен",
  delete: "Уровень удален",
};
