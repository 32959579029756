import { api } from "@api";
import {
  ApiItemRes,
  ApiQuestionDeleteParams,
  ApiQuestionListGet,
  ApiQuestionPathParams,
  ApuQuestionListGetParams,
  ApuQuestionPostParams,
} from "@types";

export const questionServices = () => {
  const getQuestionList = (data: ApuQuestionListGetParams) => (
    api.get<ApiQuestionListGet>(`content/skills/${data.skillSlug}/levels/${data.levelSlug}/questions/`)
  );

  const createQuestion = (data: ApuQuestionPostParams) => (
    api.post<ApiItemRes>(`content/skills/${data.skillId}/levels/${data.levelId}/questions/`, data.question)
  );

  const editQuestion = (data: ApiQuestionPathParams) => (
    api.patch<ApiItemRes>(
      `content/skills/${data.skillId}/levels/${data.levelId}/questions/${data.questionId}/`,
      data.question,
    )
  );

  const deleteQuestion = (data: ApiQuestionDeleteParams) => (
    api.delete<ApiItemRes>(`content/skills/${data.skillId}/levels/${data.levelId}/questions/${data.questionId}/`)
  );

  return {
    getQuestionList,
    createQuestion,
    deleteQuestion,
    editQuestion,
  };
};
