import { FC } from "react";

import { Skeleton } from "@mui/material";

import { SkillItemListWrapper } from "../../components/SkillItemList/SkillItemList.styled";

type EntityListSkeletonProps = {
  countElement?: number;
};

export const EntityListSkeleton: FC<EntityListSkeletonProps> = ({ countElement = 6 }) => {
  const elements = () => new Array(countElement).fill("item");
  return (
    <SkillItemListWrapper>
      { elements().map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={ index } variant="rounded" height={ 95 } />
      )) }
    </SkillItemListWrapper>
  );
};
