import { FC, useEffect } from "react";
import { Link } from "react-router-dom";

import { SkillItemList } from "@components";
import { useAppDispatch } from "@hooks";
import { errorAlertHandle } from "@store/alertSlice/utils";
import { useGetGeneralPermissionsQuery, useGetSkillListQuery } from "@store/skillApi";
import { Skill } from "@types";
import {
  Button, EntityListSkeleton, PageHeaderWrapper, PageTitle, PageTitleWrapper,
} from "@ui";

export const SkillListPage: FC = () => {
  const dispatch = useAppDispatch();
  const { data: skillGeneralPermissions } = useGetGeneralPermissionsQuery();
  const {
    data: skills, isLoading, isError, error,
  } = useGetSkillListQuery();

  useEffect(() => {
    if (isError) {
      errorAlertHandle(error, dispatch);
    }
  }, [isError]);

  if (isError) {
    return <h1>Ошибка запроса</h1>;
  }

  return (
    <>
      <PageHeaderWrapper>
        <PageTitleWrapper>
          <PageTitle>
            Навыки
          </PageTitle>
          { skillGeneralPermissions && skillGeneralPermissions.createSkill
            && (
              <Link to="/skills/create">
                <Button variant="contained">
                  Создать навык
                </Button>
              </Link>
            ) }
        </PageTitleWrapper>
      </PageHeaderWrapper>
      { skills?.length === 0 && <h1>Навыков нет</h1> }
      { !isLoading ? <SkillItemList skills={ skills as Skill[] } /> : <EntityListSkeleton /> }
    </>

  );
};
