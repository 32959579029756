
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  colors: {
    dark: "#1F1F1F",
    blue: "#032D5D",
    gray: "#757575",
  },
  breakpoints: {
    tablet: "660px",
    mobile: "480px",
  },
  titles: {
    h1: {
      fontSize: 32,
      fontWeight: 300,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 16,
    },
  },

});
