import { FC, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { Breadcrumbs, LevelItemList } from "@components";
import { useAppDispatch } from "@hooks";
import { errorAlertHandle } from "@store/alertSlice/utils";
import { useGetLevelListQuery } from "@store/levelApi";
import { useDeleteSkillMutation, useGetPermissionsQuery, useGetSkillQuery } from "@store/skillApi";
import { Params } from "@types";
import {
  BreadcrumbsSkeleton,
  Button,
  EntityItemSkeleton,
  EntityListSkeleton, PageDescriptionWrapper,
  PageHeaderWrapper,
  PageTitle,
  PageTitleActions,
  PageTitleWrapper,
} from "@ui";

export const LevelListPage: FC = () => {
  const { skillSlug } = useParams<Params>();
  const {
    data: levelList, isLoading: isLevelLoading, isError, error,
  } = useGetLevelListQuery(skillSlug as string);
  const { data: skillItem, isLoading: isSkillLoading } = useGetSkillQuery(skillSlug as string);
  const [deleteSkill] = useDeleteSkillMutation();
  const navigate = useNavigate();
  const { data: skillPermissions } = useGetPermissionsQuery(skillItem?.id as string);
  const dispatch = useAppDispatch();

  const deleteSkillHandler = (skillId: string) => {
    const approve = confirm("Вы хотите удалить навык?");
    if (approve) {
      deleteSkill(skillId)
        .then((res) => {
          if ("data" in res) {
            navigate("/skills");
          }
        });
    }
  };

  useEffect(() => {
    if (isError) {
      errorAlertHandle(error, dispatch);
    }
  }, [isError]);

  return (
    <>
      { !isSkillLoading ? <Breadcrumbs skillName={ skillItem?.title } />
        : <BreadcrumbsSkeleton /> }
      <PageHeaderWrapper>
        { !isSkillLoading && skillItem
          ? (
            <>
              <PageTitleWrapper>
                <PageTitle>
                  { skillItem.title }
                </PageTitle>
                { skillPermissions
                  && (
                    <PageTitleActions>
                      { skillPermissions.canRemove
                        && (
                          <Button
                            variant="contained"
                            onClick={ () => deleteSkillHandler(skillItem.id) }
                            color="error"
                          >
                            Удалить
                          </Button>
                        ) }
                      { skillPermissions.canEdit
                        && (
                          <>
                            <Link to={ `/skills/edit/${skillItem.slug}` }>
                              <Button variant="contained">Редактировать</Button>
                            </Link>
                            <Link to={ `/skills/${skillSlug}/create` }>
                              <Button variant="contained">Создать уровень</Button>
                            </Link>
                          </>
                        ) }
                    </PageTitleActions>
                  ) }
              </PageTitleWrapper>
              <PageDescriptionWrapper>
                <ReactMarkdown>
                  { skillItem.description }
                </ReactMarkdown>
              </PageDescriptionWrapper>
            </>
          ) : <EntityItemSkeleton /> }
      </PageHeaderWrapper>
      { !isLevelLoading ? <LevelItemList levels={ levelList } /> : <EntityListSkeleton /> }
    </>
  );
};
