import { FC, forwardRef } from "react";

import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";

type TextFieldProps = MuiTextFieldProps;

// eslint-disable-next-line react/display-name
export const TextField: FC<TextFieldProps> = forwardRef((props, ref) => (
  <MuiTextField { ...props } ref={ ref } />
));
