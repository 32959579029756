import { FC } from "react";

import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from "@mui/material";

type TabsProps = MuiTabsProps;

export const Tabs: FC<TabsProps> = (props) => (
  <MuiTabs
    { ...props }
    sx={{ minWidth: "270px", gap: "20px" }}
    TabIndicatorProps={{
      style: {
        display: "none",
      },
    }}
  />
);
