import { FC, SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router";

import {
  Breadcrumbs,
  LevelForm,
  QuestionForm,
  QuestionList,
  SelectModeratorLevel,
} from "@components";
import AddIcon from "@mui/icons-material/Add";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { CircularProgress, Drawer, Fab } from "@mui/material";
import { useEditLevelMutation, useGetLevelPermissionsQuery, useGetLevelQuery } from "@store/levelApi";
import { useGetQuestionListQuery } from "@store/questionApi";
import { useGetSkillQuery } from "@store/skillApi";
import { FormLevel, Params, Question } from "@types";
import {
  FormContainer,
  FormTitle, PageHeaderWrapper,
  PageTitle,
  Tab,
  TabPanel,
  Tabs,
  TabsWrapper,
} from "@ui";

import {
  AddQuestionButtonWrapper,
  QuestionFormWrapper,
  QuestionListWrapper,
} from "./LevelEditPage.styled";

const LevelEditPage: FC = () => {
  const { skillSlug, levelSlug } = useParams<Params>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [questionFormValues, setQuestionFormValues] = useState<Question | undefined>(undefined);
  const { data: skillItem } = useGetSkillQuery(skillSlug as string);
  const { data: levelItem, isError: levelError } = useGetLevelQuery({
    levelSlug: levelSlug as string,
    skillSlug: skillSlug as string,
  });
  const { data: levelQuestions } = useGetQuestionListQuery({
    levelSlug: levelSlug as string,
    skillSlug: skillSlug as string,
  });
  const [editLevel] = useEditLevelMutation();

  const [selectTab, setSelectTab] = useState<number>(0);
  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setSelectTab(newValue);
  };

  const navigate = useNavigate();

  const formSubmit = async (level: FormLevel) => {
    if (levelItem) {
      editLevel({ level, levelId: levelItem.id, skillId: levelItem.skill_id })
        .then((res) => {
          if ("data" in res) {
            navigate(`/skills/${skillSlug}/edit/${level.slug}`);
          }
        });
    }
  };

  const editQuestion = (question: Question) => {
    setModalOpen((prevState) => !prevState);
    setQuestionFormValues(question);
  };

  const addQuestion = () => {
    setModalOpen((prevState) => !prevState);
  };

  const cleanQuestionForm = () => {
    setQuestionFormValues(undefined);
    setModalOpen(false);
  };

  const modalClose = () => {
    setModalOpen((prevState) => !prevState);
    setQuestionFormValues(undefined);
  };

  if (levelError) {
    navigate(`/skills/${skillSlug}`);
  }

  const { data: permissions } = useGetLevelPermissionsQuery({
    levelId: levelItem?.id as string,
    skillId: levelItem?.skill_id as string,
  });

  if (permissions && !permissions.canEdit) {
    navigate(`/skills/${skillSlug}/${levelSlug}`);
  }

  if (permissions && levelItem) {
    return (
      <>
        <Breadcrumbs skillName={ skillItem?.title } levelName={ levelItem?.title } />
        <PageHeaderWrapper>
          <PageTitle>
            Редактировать уровень
          </PageTitle>
        </PageHeaderWrapper>

        <TabsWrapper>
          <Tabs
            orientation="vertical"
            value={ selectTab }
            onChange={ handleChangeTab }
          >
            <Tab label="Уровень" icon={ <FormatAlignLeftIcon /> } />
            <Tab label="Вопросы" icon={ <QuestionMarkIcon /> } />
            <Tab label="Модераторы" icon={ <PersonIcon /> } />
          </Tabs>
          <TabPanel index={ 0 } value={ selectTab }>
            <LevelForm formSubmit={ formSubmit } defaultValues={ levelItem } />
          </TabPanel>
          <TabPanel index={ 1 } value={ selectTab }>
            <FormContainer>
              <QuestionListWrapper>
                <FormTitle>Вопросы</FormTitle>
                <QuestionList
                  questionList={ levelQuestions as Question[] }
                  editQuestionHandler={ editQuestion }
                />
              </QuestionListWrapper>
              <AddQuestionButtonWrapper>
                <Fab color="primary" aria-label="add" onClick={ () => addQuestion() }>
                  <AddIcon />
                </Fab>
              </AddQuestionButtonWrapper>
            </FormContainer>
          </TabPanel>
          <TabPanel index={ 2 } value={ selectTab }>
            <SelectModeratorLevel skillId={ levelItem.skill_id } levelId={ levelItem.id } />
          </TabPanel>
        </TabsWrapper>

        <Drawer
          anchor="right"
          open={ modalOpen }
          onClose={ () => modalClose() }
        >
          <QuestionFormWrapper>
            <QuestionForm
              levelItem={ levelItem }
              formValues={ questionFormValues }
              cleanFormValues={ cleanQuestionForm }
            />
          </QuestionFormWrapper>
        </Drawer>
      </>
    );
  }
  return <CircularProgress />;
};

export { LevelEditPage };
