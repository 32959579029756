import styled from "@emotion/styled";

export const PageTitle = styled.h1`
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
  ${({ theme }) => theme.titles.h1}
`;

export const PageInfoWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormTitle = styled.h2`
  margin-bottom: 20px;
  font-weight: 400;
  ${({ theme }) => theme.titles.h2}
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageDescriptionWrapper = styled.div`
  margin-top: 20px;
`;

export const PageHeaderWrapper = styled.div`
  margin-bottom: 40px;
`;

export const PageTitleActions = styled.div`
  flex-shrink: 0;
  align-self: flex-start;
  margin-left: 20px;
  button, a {
    margin: 0 5px;
  }
`;
