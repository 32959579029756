import { FC, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import { questionsFormErrors } from "@config";
import { createSlug } from "@helpers";
import {
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useEditQuestionMutation,
} from "@store/questionApi";
import { FormQuestion, Level, Question } from "@types";
import {
  Button, FormContent, MarkDownEditor, TextField,
} from "@ui";

import { ButtonListWrapper, DeleteButtonWrapper, Header } from "./QuestionForm.styled";

type QuestionFormProps = {
  formValues?: Question ;
  levelItem: Level;
  cleanFormValues: () => void;
};

export const QuestionForm: FC<QuestionFormProps> = ({
  formValues, levelItem, cleanFormValues,
}) => {
  const {
    handleSubmit, control, setValue, formState: { errors },
  } = useForm<FormQuestion>();
  const { id: levelId, skill_id: skillId } = levelItem;
  const [createQuestion] = useCreateQuestionMutation();
  const [deleteQuestion] = useDeleteQuestionMutation();
  const [editQuestion] = useEditQuestionMutation();

  const [isSkillSlugChanged, setIsSkillSlugChanged] = useState<boolean>(false);

  const nameValue = useWatch({
    control,
    name: "title",
  });

  const defaultValues: FormQuestion = {
    title: "",
    description: "",
    slug: "",
  };

  const isEditForm = !!formValues;

  useEffect(() => {
    if (!isSkillSlugChanged && nameValue) {
      setValue("slug", createSlug(nameValue, "-"));
    }
  }, [nameValue, isSkillSlugChanged]);

  const formSubmit = (question: FormQuestion) => {
    if (!isEditForm) {
      createQuestion({ skillId, levelId, question })
        .then((res) => {
          if ("data" in res) {
            cleanFormValues();
          }
        });
      return null;
    }

    const { id: questionId } = formValues;
    editQuestion({
      skillId, levelId, question, questionId,
    })
      .then((res) => {
        if ("data" in res) {
          cleanFormValues();
        }
      });
  };

  const deleteQuestionHandler = () => {
    if (formValues) {
      const { id: questionId } = formValues;
      deleteQuestion({ skillId, levelId, questionId })
        .then((res) => {
          if ("data" in res) {
            cleanFormValues();
          }
        });
    }
  };

  return (
    <form onSubmit={ handleSubmit(formSubmit) }>
      <Header>
        <DeleteButtonWrapper isButtonHide={ !isEditForm }>
          <Button
            variant="contained"
            color="error"
            type="button"
            onClick={ () => deleteQuestionHandler() }

          >Удалить
          </Button>
        </DeleteButtonWrapper>

        <ButtonListWrapper>
          <Button variant="contained" type="submit">Сохранить</Button>
          <Button
            variant="contained"
            color="inherit"
            onClick={ () => cleanFormValues() }
          >Отменить
          </Button>
        </ButtonListWrapper>
      </Header>

      <FormContent>
        <Controller
          control={ control }
          name="title"
          defaultValue={ formValues?.title || defaultValues?.title }
          rules={{
            required: questionsFormErrors.title,
          }}
          render={ ({ field }) => (
            <TextField
              label="Название"
              fullWidth
              { ...field }
              variant="standard"
              error={ !!errors.title }
              helperText={ errors?.title && errors.title.message }
            />
          ) }
        />
        <Controller
          control={ control }
          name="slug"
          defaultValue={ formValues?.slug || defaultValues?.slug }
          rules={{
            required: questionsFormErrors.slug,
            pattern: {
              value: /^[a-zA-Z0-9-]+$/,
              message: questionsFormErrors.slugNotValid,
            },
          }}
          render={ ({ field }) => (
            <TextField
              label="Символьный код"
              InputLabelProps={{ shrink: true }}
              { ...field }
              variant="standard"
              onInput={ () => setIsSkillSlugChanged(true) }
              error={ !!errors.slug }
              helperText={ errors?.slug && errors.slug.message }
              onChange={ (e) => setValue("slug", createSlug(e.target.value, "-")) }
            />
          ) }
        />
        <span>
          Описание
        </span>
        <Controller
          control={ control }
          defaultValue={ formValues?.description || defaultValues.description }
          name="description"
          render={ ({ field: { onChange, onBlur, value } }) => (
            <MarkDownEditor
              height={ 200 }
              onChange={ onChange }
              onBlur={ onBlur }
              value={ value }
            />
          ) }
        />
      </FormContent>
    </form>

  );
};
