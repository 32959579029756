import { FC, ReactNode } from "react";

import { Button } from "@ui";

import { LeftContentWrapper, Title, Wrapper } from "./MemberCart.styled";

type MemberCartProps = {
  title: string;
  avatar: ReactNode;
  deleteHandler: () => void;
};

export const MemberCart: FC<MemberCartProps> = ({ avatar, deleteHandler, title }) => (
  <Wrapper>
    <LeftContentWrapper>

      { avatar }

      <Title>
        { title }
      </Title>
    </LeftContentWrapper>
    <Button
      variant="contained"
      color="error"
      onClick={ () => deleteHandler() }
    >Удалить
    </Button>
  </Wrapper>
);
