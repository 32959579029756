import { FC, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import { levelFormErrors } from "@config";
import { createSlug } from "@helpers";
import { FormLevel, Level } from "@types";
import {
  Button,
  FormContainer,
  FormContent,
  FormTitle,
  MarkDownEditor,
  PageTitleWrapper,
  SubmitButtonWrapper,
  TextField,
} from "@ui";

type LevelFormProps = {
  formSubmit: (level: Level) => void;
  defaultValues?: FormLevel;
};

export const LevelForm: FC<LevelFormProps> = ({ formSubmit, defaultValues }) => {
  const {
    handleSubmit, control, setValue, formState: { errors },
  } = useForm<Level>();

  const [isSkillSlugChanged, setIsSkillSlugChanged] = useState<boolean>(false);

  const nameValue = useWatch({
    control,
    name: "title",
  });

  useEffect(() => {
    if (!isSkillSlugChanged && nameValue) {
      setValue("slug", createSlug(nameValue, "-"));
    }
  }, [nameValue, isSkillSlugChanged]);

  return (

    <form onSubmit={ handleSubmit(formSubmit) }>
      <PageTitleWrapper>
        <FormTitle>
          Уровень
        </FormTitle>
      </PageTitleWrapper>
      <FormContainer>
        <FormContent>
          <Controller
            control={ control }
            name="title"
            defaultValue={ defaultValues?.title }
            rules={{
              required: levelFormErrors.title,
            }}
            render={ ({ field }) => (
              <TextField
                label="Название"
                fullWidth
                { ...field }
                variant="standard"
                error={ !!errors.title }
                helperText={ errors.title ? errors.title.message : " " }
              />
            ) }
          />
          <Controller
            control={ control }
            name="slug"
            defaultValue={ defaultValues?.slug || "" }
            rules={{
              required: levelFormErrors.slug,
              pattern: {
                value: /^[a-zA-Z0-9-]+$/,
                message: levelFormErrors.slugNotValid,
              },
            }}
            render={ ({ field }) => (
              <TextField
                label="Символьный код"
                InputLabelProps={{ shrink: true }}
                { ...field }
                variant="standard"
                onInput={ () => {
                  setIsSkillSlugChanged(true);
                } }
                onChange={ (e) => setValue("slug", createSlug(e.target.value, "-")) }
                error={ !!errors.slug }
                helperText={ errors.slug ? errors.slug.message : " " }
              />
            ) }
          />
          <span>
            Описание
          </span>
          <Controller
            control={ control }
            defaultValue={ defaultValues?.description }
            name="description"
            render={ ({ field: { onChange, onBlur, value } }) => (
              <MarkDownEditor
                height={ 200 }
                onChange={ onChange }
                onBlur={ onBlur }
                value={ value }
              />
            ) }
          />
        </FormContent>
        <SubmitButtonWrapper>
          <Button variant="contained" type="submit">
            Сохранить
          </Button>
        </SubmitButtonWrapper>
      </FormContainer>
    </form>
  );
};
