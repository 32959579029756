import { FC } from "react";

import { Question } from "@types";

import { Wrapper } from "./QestionItem.styled";

interface QuestionItemProps {
  questionData: Question;
  clickHandler: (question: Question) => void;
}

export const QuestionItem: FC<QuestionItemProps> = ({ questionData, clickHandler }) => (
  <Wrapper onClick={ () => clickHandler(questionData) }>
    <span>{ questionData.title }</span>
  </Wrapper>
);
