import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const LinkItem = styled.div`
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 240px;
  -webkit-box-orient: vertical;
  height: 100%;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 2px;
  position: relative;

  &:before {
    position: absolute;
    bottom: -1px;
    left: 0;
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.blue};
    opacity: 0;
    transition: .3s;
    content: "";

  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const LinkItemLast = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 2px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 240px;
  -webkit-box-orient: vertical;
`;
