import styled from "@emotion/styled";

export const QuestionTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const QuestionFormWrapper = styled.div`
  padding: 20px;
  @media  (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 60vw;
  }

`;

export const QuestionListWrapper = styled.div`
  margin-bottom: 10px;
`;
export const AddQuestionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
