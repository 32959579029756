import { api } from "@api";
import {
  ApiItemRes,
  ApiLevelDeleteModerator,
  ApiLevelDeleteParams,
  ApiLevelGet,
  ApiLevelGetParams,
  ApiLevelGetPermissionsParams,
  ApiLevelListGet,
  ApiLevelPatchParams,
  ApiLevelPermissions,
  ApiLevelPostModerator,
  ApiLevelPostParams,
  ApiMemberListGet,
} from "@types";

export const levelServices = () => {
  const getLevelList = (skillSlug: string) => (
    api.get<ApiLevelListGet>(`content/skills/${skillSlug}/levels/`)
  );

  const getLevel = (data: ApiLevelGetParams) => (
    api.get<ApiLevelGet>(`content/skills/${data.skillSlug}/levels/${data.levelSlug}/`)
  );

  const createLevel = (data: ApiLevelPostParams) => (
    api.post<ApiItemRes>(`content/skills/${data.skillId}/levels/`, data.level)
  );

  const editLevel = (data: ApiLevelPatchParams) => (
    api.patch<ApiItemRes>(`content/skills/${data.skillId}/levels/${data.levelId}/`, data.level)
  );

  const deleteLevel = (data: ApiLevelDeleteParams) => (
    api.delete<ApiItemRes>(`content/skills/${data.skillId}/levels/${data.levelId}/`)
  );
  const addModeratorLevel = (data: ApiLevelPostModerator) => (
    api.post<ApiItemRes>(`content/skills/${data.skillId}/levels/${data.levelId}/moderators/`, data.moderator)
  );

  const deleteModeratorLevel = (data: ApiLevelDeleteModerator) => (
    api.delete<ApiItemRes>(`content/skills/${data.skillId}/levels/${data.levelId}/moderators/${data.moderatorId}/`)
  );

  const getModeratorListLevel = (skillId: string, levelId: string) => (
    api.get<ApiMemberListGet>(`content/skills/${skillId}/levels/${levelId}/moderators/`)
  );

  const permissionsLevel = (data: ApiLevelGetPermissionsParams) => (
    api.get<ApiLevelPermissions>(`content/skills/${data.skillId}/levels/${data.levelId}/permissions/`)
  );

  return {
    createLevel,
    getLevel,
    editLevel,
    addModeratorLevel,
    deleteModeratorLevel,
    getModeratorListLevel,
    getLevelList,
    deleteLevel,
    permissionsLevel,
  };
};
