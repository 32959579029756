import { FC } from "react";

import { css, Global } from "@emotion/react";

export const GlobalStyle: FC = () => (
  <Global
    styles={ css`
      body {
        margin: 0;
        padding: 0;
        font-family: Roboto;
      }

      * {
        box-sizing: border-box;
      }

      h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      p {
        margin: 0;
      }

    ` }
  />
);
