import { api } from "@api";
import { ApiMemberListGet } from "@types";

export const useMemberServices = () => {
  const getMemberList = (query: string) => (
    api.get<ApiMemberListGet>(`content/members/search?q=${query}`)
  );

  return {
    getMemberList,
  };
};
