import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;

`;
