import {
  ChangeEvent, FC, useEffect, useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

import { makeInitials } from "@helpers";
import { CircularProgress, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { ApiMemberPost, Member, MemberForm } from "@types";
import { Autocomplete, Button, MemberCart } from "@ui";

import { MemberListTitle, MemberListWrapper } from "./SelectMembers.styled";

type SelectMemberProps = {
  getMemberList: () => Promise<Member[]>;
  deleteMember: (memberId: string) => Promise<number | null>;
  addMember: (member: ApiMemberPost) => Promise<number | null>;
  getEntityList: (query: string) => Promise<Member[]>;
  autocompleteLabel: string;
  memberListTitle: string;
};

export const SelectMember: FC<SelectMemberProps> = ({
  getMemberList, deleteMember,
  addMember, getEntityList, autocompleteLabel, memberListTitle,
}) => {
  const [searchedQuery, setSearchedQuery] = useState<string>("");
  const [addedMemberList, setAddedMemberList] = useState<Member[] | null>(null);
  const [searchedResultList, setSearchedResultList] = useState<Member[] >([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit, control, formState: { errors }, resetField,
  } = useForm<MemberForm>();

  useEffect(() => {
    getMemberList()
      .then((res) => {
        setAddedMemberList(res);
      });
  }, []);

  useEffect(() => {
    if (searchedQuery.length > 2) {
      setLoading(true);
      getEntityList(searchedQuery)
        .then((res) => {
          setSearchedResultList(res);
          setLoading(false);
        });
    }
    setSearchedResultList([]);
  }, [searchedQuery]);

  const handleSearchInput = (query: string) => {
    setSearchedQuery(query);
  };
  const handleDeleteMember = async (memberId: string) => {
    const isMemberDeleted = await deleteMember(memberId);
    if (!isMemberDeleted) {
      return null;
    }
    const updatedAddedMemberList = addedMemberList?.filter((item) => item.id !== memberId);
    setAddedMemberList(updatedAddedMemberList as Member[]);
  };

  const handleAddMember = async (data: MemberForm) => {
    const isMemberAdd = await addMember({ memberId: data.member.id });
    if (!isMemberAdd) {
      return null;
    }

    setAddedMemberList((prevState) => [
      ...(prevState || []) as Member[],
      data.member,
    ]);
    setSearchedResultList([]);
    setSearchedQuery("");
    resetField("member");
  };

  return (
    <form onSubmit={ handleSubmit(handleAddMember) }>
      <Controller
        name="member"
        control={ control }
        rules={{ required: true }}
        render={ ({ field }) => (
          <Autocomplete
            fullWidth
            options={ searchedResultList }
            { ...field }
            clearOnBlur={ false }
            value={ field.value || null }
            getOptionLabel={ (option: Member) => option.name }
            onChange={ (event, value) => {
              field.onChange(value);
            } }
            open={ open }
            onOpen={ () => {
              setOpen(true);
            } }
            onClose={ () => {
              setOpen(false);
            } }
            loading={ loading }
            onInput={ (e: ChangeEvent<HTMLInputElement>) => {
              handleSearchInput(e.target.value);
            } }
            inputValue={ field.value ? field.value.name : searchedQuery }
            renderInput={ (params) => (
              <TextField
                label={ autocompleteLabel }
                { ...params }
                error={ !!errors.member }
                helperText={ errors.member ? errors.member.message : " " }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      { loading ? <CircularProgress color="inherit" size={ 20 } /> : null }
                      { params.InputProps.endAdornment }
                    </>
                  ),
                }}

              />
            ) }
          />
        ) }
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ marginTop: "20px" }}
      >
        Сохранить
      </Button>
      { addedMemberList && (
        <MemberListWrapper>
          <MemberListTitle>
            { memberListTitle }
          </MemberListTitle>
          { addedMemberList.map((member) => (
            <MemberCart
              key={ member.id }
              title={ member.name }
              avatar={ <Avatar>{ makeInitials(member.name) }</Avatar> }
              deleteHandler={ () => handleDeleteMember(member.id) }
            />
          )) }
        </MemberListWrapper>
      ) }
    </form>
  );
};
