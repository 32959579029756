import styled from "@emotion/styled";

export const SkillItemListWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, calc((100% / 3) - 10px));
  grid-gap: 10px 10px;
  justify-content: space-between;

  @media (max-width: ${({ theme: { breakpoints: { tablet } } }) => tablet}) {
    grid-template-columns: repeat(2,calc(50% - 10px));
  }

  @media (max-width: ${({ theme: { breakpoints: { mobile } } }) => mobile}) {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }
`;
