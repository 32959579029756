import { FC } from "react";
import { Link } from "react-router-dom";

import { Container } from "@ui";

import { HeaderContent } from "./Header.styled";

export const Header: FC = () => (
  <HeaderContent>
    <Container>
      <Link to="/skills">
        <img src={ `${process.env.PUBLIC_URL}/images/logo.svg` } alt="logo" />
      </Link>
    </Container>
  </HeaderContent>
);
