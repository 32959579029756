import { FC } from "react";

import { plural } from "@helpers";
import { Skill } from "@types";
import { Card } from "@ui";

import { SkillItemListWrapper } from "./SkillItemList.styled";

type SkillItemListProps = {
  skills: Skill[];
};

export const SkillItemList: FC<SkillItemListProps> = ({ skills }) => {
  if (skills.length > 0) {
    return (
      <SkillItemListWrapper>
        { skills.map((skillsItem) => {
          const skillsItemBottom = `${skillsItem.count_levels} ${plural(["уровень", "уровня", "уровней"], skillsItem.count_levels)}`;
          return (
            <Card
              key={ skillsItem.id }
              itemLink={ `/skills/${skillsItem.slug}` }
              title={ skillsItem.title }
              bottomContent={ skillsItemBottom }
            />
          );
        }) }
      </SkillItemListWrapper>
    );
  }

  return null;
};
