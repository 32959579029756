import styled from "@emotion/styled";

export const MemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin-top: 45px;
`;

export const MemberListTitle = styled.h2`
  font-weight: 400;
  ${({ theme }) => theme.titles.h2}

`;
