import CyrillicToTranslit from "cyrillic-to-translit-js";

// @ts-ignore
const cyrillicToTranslit = new CyrillicToTranslit();
export const createSlug = (
  text: string,
  divider: string,
): string => cyrillicToTranslit.transform(text, divider)
  .toLowerCase()
  .replace(/[^\w\s-]/g, "")
  .replace(/_/g, "-")
  .replace(/-+/g, "-");
