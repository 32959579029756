import * as React from "react";
import { FC } from "react";

import { SelectMember } from "@components";
import { useAppDispatch, useMemberServices } from "@hooks";
import { skillServices } from "@services";
import { errorAlertHandle, successAlertHandle } from "@store/alertSlice/utils";
import { ApiMemberPost, Member } from "@types";

type SelectModeratorSkillProps = {
  skillId: string;
};

export const SelectModeratorSkill: FC<SelectModeratorSkillProps> = ({ skillId }) => {
  const { getModeratorListSkill, addModeratorSkill, deleteModeratorSkill } = skillServices();
  const { getMemberList } = useMemberServices();
  const dispatch = useAppDispatch();

  const getMemberListHandler = (query: string) => getMemberList(query).then<Member[]>(({ data: { data: { items } } }) => items);

  const getModeratorListSkillHandler = () => getModeratorListSkill(skillId).then<Member[]>(({ data: { data: { items } } }) => items);
  const addModeratorHandler = (moderator: ApiMemberPost) => addModeratorSkill(skillId, moderator)
    .then((res) => {
      successAlertHandle("Модератор назначен", dispatch);

      return res.status;
    })
    .catch((er) => {
      errorAlertHandle(er, dispatch);
      return null;
    });

  const deleteModeratorHandler = async (moderatorId: string) => deleteModeratorSkill(skillId, moderatorId)
    .then((res) => {
      successAlertHandle("Модератор удален", dispatch);
      return res.status;
    })
    .catch((er) => {
      errorAlertHandle(er, dispatch);
      return null;
    });

  return (
    <SelectMember
      getMemberList={ getModeratorListSkillHandler }
      deleteMember={ deleteModeratorHandler }
      addMember={ addModeratorHandler }
      getEntityList={ getMemberListHandler }
      autocompleteLabel="Имя пользователя"
      memberListTitle="Модераторы"
    />
  );
};
