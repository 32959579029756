import { AxiosError } from "axios";

export const axiosBaseQuery = async (
  data: {
    func: (data: any) => any;
    params: any;
  },
): Promise<any> => {
  const { func, params } = data;
  try {
    const res = await func(params);
    return { data: res.data };
  } catch (axiosError: any) {
    let err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
