import { skillSuccessAlertMessage } from "@config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { skillServices } from "@services";
import { axiosBaseQuery } from "@store/apiBaseQuery";
import { showAlert } from "@store/showAlert";
import {
  ApiItemRes, ApiSkillPatch, ApiSkillPost, Skill, SkillGeneralPermissions, SkillPermissions,
} from "@types";

const {
  getSkillList, getSkill, createSkill, deleteSkill, editSkill, getSkillPermissions, getSkillGeneralPermissions,
} = skillServices();

export const skillApi = createApi({
  reducerPath: "skillApi",
  tagTypes: ["Skills"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getSkillList: builder.query<Skill[], void>({
      query: (arg) => ({ func: getSkillList, params: arg }),
      transformResponse: (response) => response.data.items,
    }),
    getSkill: builder.query<Skill, string>({
      query: (skillSlug) => ({ func: getSkill, params: skillSlug }),
      transformResponse: (response) => response.data,
    }),
    createSkill: builder.mutation<ApiItemRes, ApiSkillPost>({
      query: (skillSlug) => ({ func: createSkill, params: skillSlug }),
      async onQueryStarted(arg, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: skillSuccessAlertMessage.create });
      },
    }),
    editSkill: builder.mutation<ApiItemRes, ApiSkillPatch>({
      query: (skillSlug) => ({ func: editSkill, params: skillSlug }),
      async onQueryStarted(_, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: skillSuccessAlertMessage.edit });
      },
    }),
    deleteSkill: builder.mutation<ApiItemRes, string>({
      query: (skillId) => ({ func: deleteSkill, params: skillId }),
      async onQueryStarted(_, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: skillSuccessAlertMessage.edit });
      },
    }),
    getPermissions: builder.query<SkillPermissions, string>({
      query: (skillId) => ({ func: getSkillPermissions, params: skillId }),
      transformResponse: (response) => response.data,
    }),
    getGeneralPermissions: builder.query<SkillGeneralPermissions, void>({
      query: (arg) => ({ func: getSkillGeneralPermissions, params: arg }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetSkillListQuery,
  useGetSkillQuery,
  useCreateSkillMutation,
  useDeleteSkillMutation,
  useEditSkillMutation,
  useGetPermissionsQuery,
  useGetGeneralPermissionsQuery,
} = skillApi;
