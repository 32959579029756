import styled from "@emotion/styled";

export const Wrapper = styled.div`
  background: rgba(242, 242, 242, 0.65);
  border-radius: 10px;
  padding: 27px 15px 8px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: scale(1.01);
  }

  & > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-content: center;
  gap: 15px;
  button {
    z-index: 2;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-width: 240px;
  -webkit-box-orient: vertical;
`;

export const CountValue = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
  flex-shrink: 1;
`;
