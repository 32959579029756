import { showAlertThunk } from "@store/alertSlice/async";

export const errorAlertHandle = (error: any, dispatch: any) => {
  const errorMessage = error.data.errors.error;
  dispatch(showAlertThunk({ message: errorMessage, type: "error" }));
};

export const successAlertHandle = (message: string, dispatch: any) => {
  dispatch(showAlertThunk({ message, type: "success" }));
};
