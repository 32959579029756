import { showAlertThunk } from "@store/alertSlice/async";

type showAlertParams = {
  dispatch: any;
  successMessage: string;
  queryFulfilled: Promise<unknown>;
};
export const showAlert = (data: showAlertParams) => {
  const {
    queryFulfilled, successMessage, dispatch,
  } = data;
  queryFulfilled
    .then(() => {
      dispatch(showAlertThunk({ message: successMessage, type: "success" }));
    })
    .catch((error: any) => {
      dispatch(showAlertThunk({ message: error.error.data.errors.message, type: "error" }));
    });
};
