import { FC } from "react";
import { useParams } from "react-router";

import { plural } from "@helpers";
import { Level, Params } from "@types";
import { Card } from "@ui";

import { SkillItemListWrapper } from "../SkillItemList/SkillItemList.styled";

type LevelItemListProps = {
  levels?: Level[];
};

export const LevelItemList: FC<LevelItemListProps> = ({ levels }) => {
  const { skillSlug } = useParams<Params>();

  if (!levels?.length) {
    return (<SkillItemListWrapper>Пока ни один уровень не добавлен</SkillItemListWrapper>);
  }

  return (
    <SkillItemListWrapper>
      { levels.map(({
        id, title, slug, count_question: countQuestions,
      }) => {
        const levelsItemBottom = countQuestions > 0 ? `${countQuestions} ${plural(["вопрос", "вопроса", "вопросов"], countQuestions)}` : null;
        return (
          <Card
            key={ id }
            itemLink={ `/skills/${skillSlug}/${slug}` }
            title={ title }
            bottomContent={ levelsItemBottom }
          />
        );
      }) }
    </SkillItemListWrapper>
  );
};
