import { FC } from "react";
import { Link } from "react-router-dom";

import {
  CountValue,
  TextWrapper,
  Title,
  Wrapper,
} from "./Card.styled";

type SkillUpItemProps = {
  title: string;
  itemLink: string;
  bottomContent: string | null;
};

export const Card: FC<SkillUpItemProps> = ({
  title, bottomContent, itemLink,
}) => (
  <Wrapper>
    <Link to={ itemLink } />
    <TextWrapper>
      <Title>{ title }</Title>
      { bottomContent && <CountValue> { bottomContent } </CountValue> }
    </TextWrapper>
  </Wrapper>

);
