import { FC } from "react";
import { useNavigate } from "react-router";

import { Breadcrumbs, SkillForm } from "@components";
import { useCreateSkillMutation, useGetGeneralPermissionsQuery } from "@store/skillApi";
import { PageHeaderWrapper, PageTitle } from "@ui";

import { FormSkill } from "../../types/skill";

const SkillCreatePage: FC = () => {
  const navigate = useNavigate();
  const [createSkill] = useCreateSkillMutation();

  const { data: skillGeneralPermissions } = useGetGeneralPermissionsQuery();
  const formSubmit = (skill: FormSkill) => {
    createSkill(skill)
      .then((res) => {
        if ("data" in res) {
          navigate(`/skills/edit/${skill.slug}`);
        }
      });
  };

  if (skillGeneralPermissions && !skillGeneralPermissions.createSkill) {
    navigate("/skills");
    return (
      <h1>Нет доступа</h1>
    );
  }

  return (
    <>
      <Breadcrumbs />
      <PageHeaderWrapper>
        <PageTitle>
          Создать навык
        </PageTitle>
      </PageHeaderWrapper>
      <SkillForm formSubmit={ formSubmit } />

    </>
  );
};

export { SkillCreatePage };
