type RouteBase = {
  main: string;
  skillCreate: string;
  skillEdit: string;
  test: string;
  skills: string;
  levelCreate: string;
  levelEdit: string;
  skillDetail: string;
  levelDetail: string;
};

export const routesService: RouteBase = {
  main: "/",
  skills: "/skills",
  skillCreate: "/skills/create",
  skillEdit: "/skills/edit/:skillSlug",
  levelCreate: "/skills/:skillSlug/create",
  levelEdit: "/skills/:skillSlug/edit/:levelSlug",
  levelDetail: "/skills/:skillSlug/:levelSlug",
  test: "/test",
  skillDetail: "/skills/:skillSlug",
};
