import { questionSuccessAlertMessage } from "@config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { questionServices } from "@services";
import { axiosBaseQuery } from "@store/apiBaseQuery";
import { showAlert } from "@store/showAlert";
import {
  ApiItemRes,
  ApiQuestionDeleteParams,
  ApiQuestionPathParams,
  ApuQuestionListGetParams,
  ApuQuestionPostParams,
  Question,
} from "@types";

const {
  getQuestionList, createQuestion, deleteQuestion, editQuestion,
} = questionServices();
export const questionApi = createApi({
  reducerPath: "questionApi",
  tagTypes: ["Questions"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getQuestionList: builder.query<Question[], ApuQuestionListGetParams>({
      query: (arg) => ({ func: getQuestionList, params: arg }),
      transformResponse: (response) => response.data.items,
      providesTags: (result) => (result ? [
        ...result.map(({ id }) => ({ type: "Questions", id } as const)),
        { type: "Questions", id: "LIST" },
      ]
        : [{ type: "Questions", id: "LIST" }]),
    }),
    createQuestion: builder.mutation<ApiItemRes, ApuQuestionPostParams>({
      query: (arg) => ({ func: createQuestion, params: arg }),
      invalidatesTags: [{ type: "Questions", id: "LIST" }],
      async onQueryStarted(arg, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: questionSuccessAlertMessage.create });
      },
    }),
    deleteQuestion: builder.mutation<ApiItemRes, ApiQuestionDeleteParams>({
      query: (arg) => ({ func: deleteQuestion, params: arg }),
      invalidatesTags: [{ type: "Questions", id: "LIST" }],
      async onQueryStarted(_, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: questionSuccessAlertMessage.delete });
      },
    }),
    editQuestion: builder.mutation<ApiItemRes, ApiQuestionPathParams>({
      query: (arg) => ({ func: editQuestion, params: arg }),
      invalidatesTags: [{ type: "Questions", id: "LIST" }],
      async onQueryStarted(_, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: questionSuccessAlertMessage.edit });
      },
    }),

  }),
});

export const {
  useGetQuestionListQuery,
  useDeleteQuestionMutation,
  useEditQuestionMutation,
  useCreateQuestionMutation,
} = questionApi;
