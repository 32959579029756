import { levelSuccessAlertMessage } from "@config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { levelServices } from "@services";
import { axiosBaseQuery } from "@store/apiBaseQuery";
import { showAlert } from "@store/showAlert";
import {
  ApiItemRes,
  ApiLevelDeleteParams,
  ApiLevelGetParams,
  ApiLevelGetPermissionsParams, ApiLevelPatchParams,
  ApiLevelPostParams,
  Level,
  LevelPermissions,
} from "@types";

const {
  getLevelList, getLevel, deleteLevel, createLevel, editLevel, permissionsLevel,
} = levelServices();
export const levelApi = createApi({
  reducerPath: "levelApi",
  tagTypes: ["Levels"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getLevelList: builder.query<Level[], string>({
      query: (arg) => ({ func: getLevelList, params: arg }),
      transformResponse: (response) => response.data.items,
    }),
    getLevel: builder.query<Level, ApiLevelGetParams>({
      query: (arg) => ({ func: getLevel, params: arg }),
      transformResponse: (response) => response.data,
    }),
    createLevel: builder.mutation<ApiItemRes, ApiLevelPostParams>({
      query: (arg) => ({ func: createLevel, params: arg }),
      async onQueryStarted(arg, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: levelSuccessAlertMessage.create });
      },
    }),
    deleteLevel: builder.mutation<ApiItemRes, ApiLevelDeleteParams>({
      query: (arg) => ({ func: deleteLevel, params: arg }),
      async onQueryStarted(_, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: levelSuccessAlertMessage.delete });
      },
    }),
    editLevel: builder.mutation<ApiItemRes, ApiLevelPatchParams>({
      query: (arg) => ({ func: editLevel, params: arg }),
      async onQueryStarted(_, {
        queryFulfilled, dispatch,
      }) {
        showAlert({ dispatch, queryFulfilled, successMessage: levelSuccessAlertMessage.edit });
      },
    }),
    getLevelPermissions: builder.query<LevelPermissions, ApiLevelGetPermissionsParams>({
      query: (skillId) => ({ func: permissionsLevel, params: skillId }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetLevelListQuery,
  useGetLevelQuery,
  useDeleteLevelMutation,
  useCreateLevelMutation,
  useEditLevelMutation,
  useGetLevelPermissionsQuery,
} = levelApi;
