import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

const Wrapper = styled.div`
  span {
    transform: scale(1,1);
  }
`;

const Description = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const EntityItemSkeleton = () => (
  <Wrapper>
    <Skeleton width="40%" height={ 38 } variant="text" />
    <Description>
      <Skeleton height={ 19 } variant="text" />
      <Skeleton height={ 19 } width="60%" variant="text" />
    </Description>
  </Wrapper>
);
