
export const skillSuccessAlertMessage = {
  create: "Навык создан",
  edit: "Навык изменен",
  delete: "Навык удален",
};

export const skillFormErrors = {
  title: "Заполните название",
  slug: "Заполните символьный код",
  slugNotValid: "Только буквы латиницы, цифры и '-' ",
};
