import { FC } from "react";
import { useNavigate, useParams } from "react-router";

import { Breadcrumbs, LevelForm } from "@components";
import { useCreateLevelMutation } from "@store/levelApi";
import { useGetPermissionsQuery, useGetSkillQuery } from "@store/skillApi";
import { FormLevel, Params } from "@types";

const LevelCreatePage: FC = () => {
  const { skillSlug } = useParams<Params>();
  const { data: skillItem } = useGetSkillQuery(skillSlug as string);
  const [createLevel] = useCreateLevelMutation();

  const navigate = useNavigate();

  const { data: permissions } = useGetPermissionsQuery(skillItem?.id as string);

  if (skillItem && permissions && !permissions.canEdit) {
    navigate(`/skills/${skillItem.slug}`);
  }

  const formSubmit = async (level: FormLevel) => {
    if (skillItem) {
      const { id: skillId, slug } = skillItem;
      createLevel({ skillId, level })
        .then((res) => {
          if ("data" in res) {
            navigate(`/skills/${slug}/edit/${level.slug}`);
          }
        });
    }
  };

  if (!skillItem) {
    return <div> <strong>{ skillSlug }</strong>  такого навыка нет</div>;
  }

  return (
    <>
      <Breadcrumbs skillName={ skillItem?.title } />
      <LevelForm formSubmit={ formSubmit } />
    </>
  );
};

export { LevelCreatePage };
