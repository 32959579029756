export const questionsFormErrors = {
  title: "Заполните название",
  slug: "Заполните символьный код",
  slugNotValid: "Только буквы латиницы, цифры и '-' ",
  points: "Заполните количество очков",
  pointsNotValid: "Только числовое значение",
};

export const questionSuccessAlertMessage = {
  create: "Вопрос создан",
  edit: "Вопрос изменен",
  delete: "Вопрос удален",
};
