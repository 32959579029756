import { FC } from "react";

import { Tab as MuiTab, TabProps as MuiTabProps } from "@mui/material";

type TabProps = MuiTabProps;

export const Tab: FC<TabProps> = ({ children, ...props }) => (
  <MuiTab
    { ...props }
    iconPosition="start"
    sx={{
      gap: "35px",
      justifyContent: "start",
      padding: "0",
      minHeight: "18px",
      fontSize: "16px",
      lineHeight: "150%",
      textTransform: "none",
      "&.Mui-selected": {
        fontWeight: "bold",
        color: "#000",
      },
      "& + &": {
        marginTop: "25px",
      },

    }}
    disableRipple
    disableFocusRipple
    wrapped
  >
    { children }
  </MuiTab>
);
