import styled from "@emotion/styled";

type IconWrapperProps = {
  isRotate: boolean;
};

export const IconWrapper = styled.div<IconWrapperProps>`
  width: 24px;
  height: 24px;
  transform: ${({ isRotate }) => (isRotate ? "rotate(180deg)" : "none")};
  transition: .3s;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  gap: 0 10px;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &::after {
    position: absolute;
    background: white;
    top: -2px;
    left: 0;
    height: 2px;
    content: "";
    width: 100px;
  }
`;
