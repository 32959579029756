
import { FC, ReactNode } from "react";

import { Wrapper } from "./TabPanel.styled";

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

export const TabPanel: FC<TabPanelProps> = ({
  children, value, index, ...other
}) => (
  <Wrapper
    role="tabpanel"
    hidden={ value !== index }
    { ...other }
  >
    { value === index && (
      children
    ) }
  </Wrapper>
);
