import { FC } from "react";
import { YMInitializer } from "react-yandex-metrika";

export const YaCounter: FC = () => {
  if (!window.config.yaCounter) {
    return null;
  }

  return (
    <YMInitializer
      accounts={ [+window.config.yaCounter] }
      options={{ webvisor: true }}
      version="2"
    />
  );
};
