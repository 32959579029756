import { api } from "@api";
import { Config } from "@config";
import Keycloak from "keycloak-js";

export const keycloakInstance = new Keycloak(
  {
    clientId: Config.kkClientId,
    realm: Config.kkRealm,
    url: Config.kkUrl,
  },
);

api.interceptors.request.use((config) => {
  if (keycloakInstance.token) {
    const cb = () => {
      config!.headers!.Authorization = `Bearer ${keycloakInstance.token}`;
      return Promise.resolve(config);
    };

    return keycloakInstance.updateToken(5).then(cb);
  }
});
