import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  padding: 18px 10px;
  font-size: 16px;
  cursor: pointer;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
