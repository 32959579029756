import styled from "@emotion/styled";

type ContainerProps = {
  paddingTop?: number;
  paddingBottom?: number;
};

export const Container = styled.div<ContainerProps>`
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}px;`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}px;`}
`;

export const FormContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
`;
