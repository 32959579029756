import styled from "@emotion/styled";

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;
