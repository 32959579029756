import { FC, ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

const portalWrapper = document.createElement("div");

type PortalProps = {
  children: ReactNode;
};
export const Portal: FC<PortalProps> = ({ children }) => {
  useEffect(() => {
    document.body.appendChild(portalWrapper);
  }, []);

  return (
    createPortal(children, portalWrapper)
  );
};
