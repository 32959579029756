import { FC, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import { skillFormErrors } from "@config";
import { createSlug } from "@helpers";
import { Skill } from "@types";
import {
  Button,
  Container,
  FormContainer,
  FormContent,
  FormTitle,
  MarkDownEditor,
  SubmitButtonWrapper,
  TextField,
} from "@ui";

type SkillFormProps = {
  formSubmit: (skill: Skill) => void;
  defaultValues?: Skill ;
};

export const SkillForm: FC<SkillFormProps> = ({ formSubmit, defaultValues }) => {
  const {
    handleSubmit, control, setValue, formState: { errors },
  } = useForm<Skill>();

  const [isSkillSlugChanged, setIsSkillSlugChanged] = useState<boolean>(false);

  const nameValue = useWatch({
    control,
    name: "title",
  });

  useEffect(() => {
    if (!isSkillSlugChanged && nameValue) {
      setValue("slug", createSlug(nameValue, "-"));
    }
  }, [nameValue, isSkillSlugChanged]);

  return (
    <Container>
      <form onSubmit={ handleSubmit(formSubmit) }>
        <FormContainer>
          <FormTitle>
            Навык
          </FormTitle>
          <FormContent>
            <Controller
              control={ control }
              name="title"
              defaultValue={ defaultValues?.title }
              rules={{
                required: skillFormErrors.title,
              }}
              render={ ({ field }) => (
                <TextField
                  label="Название"
                  fullWidth
                  { ...field }
                  variant="standard"
                  error={ !!errors.title }
                  helperText={ errors.title ? errors.title.message : " " }
                />
              ) }
            />
            <Controller
              control={ control }
              name="slug"
              defaultValue={ defaultValues?.slug }
              rules={{
                required: skillFormErrors.slug,
                pattern: {
                  value: /^[a-zA-Z0-9-]+$/,
                  message: skillFormErrors.slugNotValid,
                },
              }}
              render={ ({ field }) => (
                <TextField
                  label="Символьный код"
                  InputLabelProps={{ shrink: true }}
                  { ...field }
                  variant="standard"
                  onInput={ () => setIsSkillSlugChanged(true) }
                  error={ !!errors.slug }
                  helperText={ errors.slug ? errors.slug.message : " " }
                  onChange={ (e) => setValue("slug", createSlug(e.target.value, "-")) }
                />
              ) }
            />
            <span>
              Описание
            </span>
            <Controller
              control={ control }
              defaultValue={ defaultValues?.description }
              name="description"
              render={ ({ field: { onChange, onBlur, value } }) => (
                <MarkDownEditor
                  height={ 200 }
                  onChange={ onChange }
                  onBlur={ onBlur }
                  value={ value }
                />
              ) }
            />
          </FormContent>
          <SubmitButtonWrapper>
            <Button variant="contained" type="submit">
              Сохранить
            </Button>
          </SubmitButtonWrapper>
        </FormContainer>
      </form>
    </Container>
  );
};
