import styled from "@emotion/styled";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

type DeleteButtonWrapperProps = {
  isButtonHide?: boolean;
};

export const DeleteButtonWrapper = styled.div<DeleteButtonWrapperProps>`
  width: 1px;
  button {
    display: ${({ isButtonHide }) => (isButtonHide ? "none" : "inline-block")};
  }
`;

export const ButtonListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
