import { FC, ReactNode, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { IconWrapper, TitleWrapper, Wrapper } from "./QuestionAccordion.styled";

type QuestionAccordionProps = {
  title: ReactNode;
  body: ReactNode;
};

export const QuestionAccordion: FC<QuestionAccordionProps> = ({ title, body }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  return (
    <Wrapper>
      <Accordion
        sx={{
          bgcolor: "transparent",
          boxShadow: "none",
          position: "relative",
          borderRadius: "10px",
        }}
        disableGutters
        onChange={ () => {
          setIsAccordionOpen((prevState) => !prevState);
        } }
      >
        <AccordionSummary
          sx={{
            bgcolor: "#E0E1E1",
            fontWeight: "bold",
            borderRadius: "5px",
          }}
        >
          <TitleWrapper>
            <IconWrapper isRotate={ isAccordionOpen }>
              <ExpandMoreIcon />
            </IconWrapper>
            { title }
          </TitleWrapper>

        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "transparent" }}>
          { body }
        </AccordionDetails>
      </Accordion>
    </Wrapper>

  );
};
