import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAlert, deleteAlert } from "@store/alertSlice/alertSlice";

import { AlertItem, AlertState } from "./types";

export type AlertStateThunk = {
  state: {
    alert: AlertState;
  };
};

export const showAlertThunk = createAsyncThunk<unknown, AlertItem, AlertStateThunk>(
  "alert/ShowErrorAlert",
  (alert: AlertItem, { dispatch, getState }) => {
    const { alert: { alertShowTime } } = getState();
    dispatch(addAlert(alert));
    setTimeout(() => {
      dispatch(deleteAlert(alert));
    }, alertShowTime);
  },
);
