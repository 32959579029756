import { FC } from "react";

import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

type BreadCrumbsSkeletonProps = {
  countElements?: number;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 20px;
`;

const WrapperItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 2px;
`;

export const BreadcrumbsSkeleton: FC<BreadCrumbsSkeletonProps> = ({ countElements = 2 }) => {
  const elements = () => new Array(countElements).fill("item");
  return (
    <Wrapper>
      <div>🍞</div>
      { elements().map((_, index) => {
        const isLastItem = index + 1 === elements().length;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <WrapperItem key={ index }>
            <Skeleton variant="rounded" height={ 32 } width={ 120 } />
            { !isLastItem ? "/" : "" }
          </WrapperItem>
        );
      }) }
    </Wrapper>
  );
};
