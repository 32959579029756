import { FC } from "react";
import ReactMarkdown from "react-markdown";

import { QuestionAccordion } from "@components";
import { Question } from "@types";

import { QuestionTitle, Wrapper } from "./QuestionAccordionList.styled";

type QuestionAccordionListProps = {
  list?: Question[];
};

export const QuestionAccordionList: FC<QuestionAccordionListProps> = ({ list }) => {
  if (!list?.length) {
    return <div>Вопросов нет</div>;
  }
  return (
    <Wrapper>
      { list.map(({
        id, title, description,
      }) => (
        <QuestionAccordion
          key={ id }
          title={ (
            <QuestionTitle>
              <div>{ title }</div>
            </QuestionTitle>
          ) }
          body={ (
            <div>
              <ReactMarkdown>
                { description }
              </ReactMarkdown>
            </div>
          ) }
        />
      )) }
    </Wrapper>

  );
};
