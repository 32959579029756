import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import { theme } from "@styles/theme";
import { Params } from "@types";

import { LinkItem, LinkItemLast, Wrapper } from "./Breadcrumbs.styled";

type BreadcrumbsProps = {
  skillName?: string;
  levelName?: string;
};
type PathListItem = {
  title: string;
  path: string;
};
export const Breadcrumbs: FC<BreadcrumbsProps> = ({ skillName, levelName }) => {
  const [pathList, setPathList] = useState<PathListItem[]>([]);
  const { skillSlug, levelSlug } = useParams<Params>();
  const { pathname } = useLocation();
  const isEditPage = pathname.split("/").includes("edit");
  const isCreatePage = pathname.split("/").includes("create");

  useEffect(() => {
    const allPathList: PathListItem[] = [];
    if (skillName && skillSlug) {
      allPathList.push({ title: skillName, path: `/skills/${skillSlug}` });
    }

    if (levelName && levelSlug) {
      allPathList.push({
        title: levelName,
        path: `/skills/${skillSlug}/${levelSlug}`,
      });
    }

    if (isEditPage) {
      allPathList.push({
        title: `Редактирование ${allPathList.at(-1)?.title}`,
        path: "/",
      });
    }

    if (isCreatePage) {
      const createPathTitle = levelSlug ? "уровень" : "навык";
      allPathList.push({
        title: `Создать ${createPathTitle}`,
        path: "/",
      });
    }
    setPathList(allPathList);
  }, [skillName, levelName]);

  if (!pathList) return null;
  return (
    <Wrapper>
      <MUIBreadcrumbs aria-label="breadcrumb" sx={{ color: theme.colors.blue }}>
        <Link to="/skills">
          <LinkItem>
            🍞 Навыки
          </LinkItem>
        </Link>
        { pathList?.map((pathItem, index) => {
          const isFinalElement = index === pathList.length - 1;
          if (!isFinalElement) {
            return (
              <Link key={ pathItem?.title } to={ pathItem?.path as string }>
                <LinkItem>
                  { pathItem?.title }
                </LinkItem>
              </Link>
            );
          }
          return <LinkItemLast key={ pathItem?.title }>{ pathItem?.title }</LinkItemLast>;
        }) }

      </MUIBreadcrumbs>
    </Wrapper>
  );
};
