import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import alertReducer from "./alertSlice/alertSlice";
import { levelApi } from "./levelApi";
import { questionApi } from "./questionApi";
import { skillApi } from "./skillApi";

const store = configureStore({
  reducer: {
    alert: alertReducer,
    [skillApi.reducerPath]: skillApi.reducer,
    [levelApi.reducerPath]: levelApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    skillApi.middleware,
    levelApi.middleware,
    questionApi.middleware,
  ),
});

setupListeners(store.dispatch);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
