import React, { Suspense } from "react";
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from "react-router-dom";

import { AuthProvider } from "@auth";
import { ThemeProvider } from "@mui/material/styles";
import { LevelDetailPage } from "@pages/LevelDetailPage";
import { LevelCreatePage } from "@pages/LevellCreatePage";
import { LevelEditPage } from "@pages/LevellEditPage";
import { LevelListPage } from "@pages/LevelListPage";
import { SkillCreatePage } from "@pages/SkillCreatePage";
import { SkillEditPage } from "@pages/SkillEditPage";
import { SkillListPage } from "@pages/SkillListPage";
import { TestPage } from "@pages/TestPage";
import { routesService } from "@routes/routes";
import { theme } from "@styles/theme";
import { GlobalStyle } from "@ui";

import { YaCounter } from "./components/YaCounter";
import { Layout } from "./layout";

const App = () => (
  <ThemeProvider theme={ theme }>
    <GlobalStyle />
    <AuthProvider>
      <Router>
        <YaCounter />
        <Suspense fallback={ <h1>Error</h1> }>
          <Layout>
            <Routes>
              <Route path={ routesService.main } element={ <Navigate to={ routesService.skills } /> } />
              <Route path={ routesService.skillCreate } element={ <SkillCreatePage /> } />
              <Route path={ routesService.skillEdit } element={ <SkillEditPage /> } />
              <Route path={ routesService.levelCreate } element={ <LevelCreatePage /> } />
              <Route path={ routesService.levelEdit } element={ <LevelEditPage /> } />
              <Route path={ routesService.test } element={ <TestPage /> } />
              <Route path={ routesService.skills } element={ <SkillListPage /> } />
              <Route path={ routesService.skillDetail } element={ <LevelListPage /> } />
              <Route path={ routesService.levelDetail } element={ <LevelDetailPage /> } />
            </Routes>
          </Layout>
        </Suspense>
      </Router>
    </AuthProvider>
  </ThemeProvider>

);

export default App;
