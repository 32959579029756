import { FC } from "react";

import { QuestionItem } from "@components";
import { Question } from "@types";

interface QuestionListProps {
  questionList: Question[];
  editQuestionHandler: (question: Question) => void;
}

export const QuestionList: FC<QuestionListProps> = ({ questionList, editQuestionHandler }) => (
  <div>
    { questionList.map((questionItem) => (
      <QuestionItem key={ questionItem.id } questionData={ questionItem } clickHandler={ editQuestionHandler } />
    )) }
  </div>
);
