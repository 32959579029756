import { SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Breadcrumbs, SelectModeratorSkill, SkillForm } from "@components";
import { useAppDispatch } from "@hooks";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import PersonIcon from "@mui/icons-material/Person";
import { CircularProgress } from "@mui/material";
import { showAlertThunk } from "@store/alertSlice/async";
import {
  useEditSkillMutation,
  useGetPermissionsQuery,
  useGetSkillQuery,
} from "@store/skillApi";
import { Params, Skill } from "@types";
import {
  PageHeaderWrapper,
  PageTitle, Tab, TabPanel, Tabs, TabsWrapper,
} from "@ui";

const SkillEditPage = () => {
  const { skillSlug } = useParams<Params>();
  const { data: skillItem, error: skillError, isLoading } = useGetSkillQuery(skillSlug as string);
  const [editSkill] = useEditSkillMutation();

  const { data: skillPermissions } = useGetPermissionsQuery(skillItem?.id as string);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectTab, setSelectTab] = useState<number>(0);
  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setSelectTab(newValue);
  };

  const formSubmit = (skill: Skill) => {
    if (skillItem) {
      editSkill({ id: skillItem.id, skill })
        .then((res) => {
          if ("data" in res) {
            navigate(`/skills/edit/${skill.slug}`);
          }
        });
    }
  };

  if (skillItem && skillPermissions && !skillPermissions.canEdit) {
    navigate(`/skills/${skillItem.slug}`);
  }

  if (skillError) {
    dispatch(showAlertThunk({ type: "error", message: skillError.data.errors.message }));
    navigate("/skills/");
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <>
      <Breadcrumbs skillName={ skillItem?.title } />
      <PageHeaderWrapper>
        <PageTitle>
          Редактировать навык
        </PageTitle>
      </PageHeaderWrapper>

      <TabsWrapper>
        <Tabs
          orientation="vertical"
          value={ selectTab }
          onChange={ handleChangeTab }
        >
          <Tab label="Навык" icon={ <FormatAlignLeftIcon /> } />
          <Tab label="Модераторы" icon={ <PersonIcon /> } disableRipple disableFocusRipple />
        </Tabs>
        <TabPanel index={ 0 } value={ selectTab }>
          <SkillForm formSubmit={ formSubmit } defaultValues={ skillItem } />
        </TabPanel>
        <TabPanel index={ 1 } value={ selectTab }>
          <SelectModeratorSkill skillId={ skillItem?.id as string } />
        </TabPanel>
      </TabsWrapper>
    </>
  );
};

export { SkillEditPage };
