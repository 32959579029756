import * as React from "react";
import { FC } from "react";

import { SelectMember } from "@components";
import { useAppDispatch, useMemberServices } from "@hooks";
import { levelServices } from "@services";
import { errorAlertHandle, successAlertHandle } from "@store/alertSlice/utils";
import { ApiMemberPost } from "@types";

type SelectModeratorLevelProps = {
  skillId: string;
  levelId: string;
};

export const SelectModeratorLevel: FC<SelectModeratorLevelProps> = ({ skillId, levelId }) => {
  const { getModeratorListLevel, addModeratorLevel, deleteModeratorLevel } = levelServices();
  const { getMemberList } = useMemberServices();
  const dispatch = useAppDispatch();

  const getMemberListHandler = (query: string) => getMemberList(query).then(({ data: { data: { items } } }) => items);

  const getModeratorListLevelHandler = () => getModeratorListLevel(skillId, levelId).then(({ data: { data: { items } } }) => items);

  const addModeratorHandler = (moderator: ApiMemberPost) => addModeratorLevel({
    skillId,
    levelId,
    moderator,
  })
    .then((res) => {
      successAlertHandle("Модератор назначен", dispatch);
      return res.status;
    })
    .catch((er) => {
      errorAlertHandle(er, dispatch);
      return null;
    });
  const deleteModeratorHandler = async (moderatorId: string) => deleteModeratorLevel({
    skillId,
    levelId,
    moderatorId,
  })
    .then((res) => {
      successAlertHandle("Модератор удален", dispatch);
      return res.status;
    })
    .catch((er) => {
      errorAlertHandle(er, dispatch);
      return null;
    });
  return (
    <SelectMember
      getMemberList={ () => getModeratorListLevelHandler() }
      deleteMember={ deleteModeratorHandler }
      addMember={ addModeratorHandler }
      getEntityList={ getMemberListHandler }
      autocompleteLabel="Имя пользователя"
      memberListTitle="Модераторы"
    />
  );
};
