import { IConfig } from "./config";

Object.freeze(window.config);

const Config: IConfig = window.config as IConfig;
export { Config };
export { levelFormErrors, levelSuccessAlertMessage } from "./levelConfig";
export { questionsFormErrors, questionSuccessAlertMessage } from "./questionFormErrors";
export { skillSuccessAlertMessage, skillFormErrors } from "./skillConfig";
export { selectMemberFormErrors } from "./selectMemberForm";
