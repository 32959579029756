import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AlertItem, AlertState } from "./types";

const initialState: AlertState = {
  errors: [],
  success: [],
  alerts: [],
  alertShowTime: 3000,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {

    addAlert(state, action: PayloadAction<AlertItem>) {
      const error = action.payload;
      state.alerts.push(error);
    },

    deleteAlert(state, action: PayloadAction<AlertItem>) {
      const { message } = action.payload;
      state.alerts = state.alerts.filter((alert) => alert.message !== message);
    },

  },
});

export const {
  addAlert,
  deleteAlert,
} = alertSlice.actions;

export default alertSlice.reducer;
