import { api } from "@api";
import {
  ApiItemRes, ApiMemberListGet,
  ApiMemberPost,
  ApiSkillGeneralPermissions,
  ApiSkillGet, ApiSkillListGet, ApiSkillPatch, ApiSkillPermissions, ApiSkillPost,
} from "@types";

export const skillServices = () => {
  const getSkill = (skillSlug: string) => (
    api.get<ApiSkillGet>(`content/skills/${skillSlug}/`)
  );

  const getSkillList = () => (
    api.get<ApiSkillListGet>("content/skills/")
  );

  const createSkill = (skill: ApiSkillPost) => (
    api.post<ApiItemRes>("content/skills/", skill)
  );

  const editSkill = (data: ApiSkillPatch) => (
    api.patch<{ id: string }>(`content/skills/${data.id}/`, data.skill));

  const deleteSkill = (skillId: string) => (
    api.delete<ApiItemRes>(`content/skills/${skillId}/`)
  );

  const addModeratorSkill = (skillId: string, moderator: ApiMemberPost) => (
    api.post<ApiItemRes>(`content/skills/${skillId}/moderators/`, moderator)
  );

  const getModeratorListSkill = (skillId: string) => (
    api.get<ApiMemberListGet>(`content/skills/${skillId}/moderators/`)
  );

  const deleteModeratorSkill = (skillId: string, moderatorId: string) => (
    api.delete<ApiItemRes>(`content/skills/${skillId}/moderators/${moderatorId}/`)
  );

  const getSkillPermissions = (skillId: string) => (
    api.get<ApiSkillPermissions>(`content/skills/${skillId}/permissions/`)
  );

  const getSkillGeneralPermissions = () => (
    api.get<ApiSkillGeneralPermissions>("content/skills/permissions/")
  );

  return {
    getSkill,
    createSkill,
    editSkill,
    getSkillList,
    deleteSkill,
    addModeratorSkill,
    deleteModeratorSkill,
    getModeratorListSkill,
    getSkillPermissions,
    getSkillGeneralPermissions,
  };
};
