import { FC, ReactNode } from "react";

import { AuthProviderProps as AuthKeycloakProviderProps } from "@react-keycloak/core";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";

import { keycloakInstance } from "./keycloack";
import { PrivateContent } from "./PrivateContent";

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const options: AuthKeycloakProviderProps<Keycloak> = {
    authClient: keycloakInstance,
    initOptions: {
      onLoad: "check-sso",
      enableLogging: true,
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    },
    autoRefreshToken: true,
  };

  return (
    <ReactKeycloakProvider { ...options }>
      <PrivateContent> { children } </PrivateContent>
    </ReactKeycloakProvider>
  );
};
