import { FC } from "react";
import { animated, useTransition } from "react-spring";

import { useAppSelector } from "@hooks";
import { Alert } from "@mui/material";
import { getAlertsSelector } from "@store/alertSlice/selectors";

import { AlertWrapper } from "./FormAlert.styled";

export const FormAlert: FC = () => {
  const alert = useAppSelector(getAlertsSelector);

  const successTransition = useTransition(
    !!alert.length,
    {
      from: { opacity: 0, x: 500 },
      enter: { opacity: 1, x: 0 },
      leave: { opacity: 0, x: 500 },
    },
  );

  const successAlerts = alert.map((successMessage) => (
    <div key={ successMessage.message }>
      { successTransition((style, item) => item && (
        <animated.div style={ style }>
          <Alert
            sx={{ width: "250px" }}
            severity={ successMessage.type }
          >{ successMessage.message }
          </Alert>
        </animated.div>
      )) }
    </div>
  ));

  return (
    <AlertWrapper>
      { successAlerts }
    </AlertWrapper>
  );
};
