import { FC, ReactNode } from "react";

import { useKeycloak } from "@react-keycloak/web";

type PrivateContentProps = {
  children: ReactNode;
};

export const PrivateContent: FC<PrivateContentProps> = ({ children }) => {
  const { initialized, keycloak } = useKeycloak();

  if (initialized && keycloak.authenticated === false) {
    keycloak.login();
  }

  return (
    <>
      { initialized && !!keycloak.authenticated && (
        children
      ) }
    </>
  );
};
