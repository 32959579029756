import { FC } from "react";

import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

type QuestionListSkeletonProps = {
  countElement?: number;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

export const QuestionListSkeleton: FC<QuestionListSkeletonProps> = ({ countElement = 6 }) => {
  const elements = () => new Array(countElement).fill("item");
  return (
    <Wrapper>
      { elements().map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={ index } variant="rounded" height={ 50 } />
      )) }
    </Wrapper>
  );
};
